<template>
  <label
    :class="{ checked: isChecked, disabled }"
    class="r-radio"
  >
    <input
      :id="id"
      ref="input"
      :disabled="disabled"
      :checked="isChecked"
      class="r-radio__input"
      type="radio"
      @change="handleChange"
    />
    <div class="r-radio__status" />
    <div>
      <r-text>
        <slot />
      </r-text>
      <r-text
        v-if="caption"
        type="caption"
        class="r-radio__caption"
        :title="caption"
      >
        {{ caption }}
      </r-text>
    </div>
  </label>
</template>

<script>
export default {
  name: 'RRadio',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    caption: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isChecked: false,
      text: ''
    }
  },
  watch: {
    checked(value) {
      this.isChecked = value
    }
  },
  mounted() {
    this.isChecked = this.checked
    this.text = this.$slots.default[0].text?.trim()
  },
  methods: {
    handleChange() {
      this.$refs.input.checked = this.isChecked
      this.$emit('change', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.r-radio {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
  padding: 0.25rem 0;
  cursor: pointer;
  align-items: center;
  flex: 1;

  &__status {
    width: 1.25rem;
    height: 1.25rem;
    border: 0.125rem solid $accent-primary;
    border-radius: 50%;
    position: relative;

    &::after {
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
      background: transparent;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      content: '';
    }
  }

  &__input {
    display: none;
  }

  &__caption {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &.checked {
    .r-radio__status::after {
      background: $accent-primary;
    }
  }

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
</style>
