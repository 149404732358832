export const colorToRgba = (color, alpha = 1) => {
  if (color.startsWith('rgb')) {
    const match = color.match(/(\d+)/g)
    const [r, g, b] = match.slice(0, 3).map(Number)

    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }

  const [r, g, b] = color.match(/\w\w/g).map(x => parseInt(x, 16))

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}
