import vue from 'vue'
import { structure, nonClearableFields } from './structure'
import { cloneDeep } from 'HELPERS'

export default {
  state: {
    ...cloneDeep(structure)
  },
  getters: {
    getParkingFields: state => key => state.fields[key],
    getParkingSelectedCity: state => state.city,
    getParkingObjectEditor: state => state.objectEditor,
    getParkingFreeTypes: state => state.tariffTypes?.free || []
  },
  mutations: {
    PARKING_STORE_RESET(state) {
      for (const field in structure) {
        if (nonClearableFields.includes(field)) continue
        state[field] = cloneDeep(structure[field])
      }
    },
    PARKING_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        state[field] = value
      }
    }
  }
}
