import Dialog from './dialog'

export default {
  install: Vue => {
    const instance = new (Vue.extend(Dialog))({
      el: document.createElement('div')
    })

    const dialog = async options => {
      return await instance.showDialog(options)
    }

    Vue.prototype.$dialog = dialog
    Vue.$dialog = dialog
  }
}
