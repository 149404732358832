import { compareSource, isArraysEqual } from 'HELPERS'

const checkChangesData = ({ source, cloneSource }) => {
  if (!cloneSource || (cloneSource && !Object.keys(cloneSource).length))
    return true

  const comparedSource = compareSource(source, cloneSource, ['geom'])
  const isGeomEqual = isArraysEqual(
    source?.geom?.coordinates,
    cloneSource?.geom?.coordinates
  )

  return !(source?.geom
    ? isGeomEqual && !Object.keys(comparedSource).length
    : !Object.keys(comparedSource).length)
}

export const validateRequiredFields = ({
  source,
  cloneSource,
  fields = []
}) => {
  let warnMessage = ''
  const unFilledFields = fields.reduce((acc, field) => {
    if (
      !(field in source) ||
      source[field] === null ||
      source[field] === undefined ||
      source[field] === '' ||
      (Array.isArray(source[field]) && !source[field].length)
    ) {
      acc.push(field)
    }

    return acc
  }, [])

  if (unFilledFields.length) {
    warnMessage = unFilledFields.includes('geom')
      ? 'select_object_map_location'
      : 'required_fields_not_filled'

    return { result: false, unFilledFields, warnMessage }
  }

  const result = checkChangesData({ source, cloneSource })

  return {
    result,
    unFilledFields,
    warnMessage: !result ? 'no_changed_data' : ''
  }
}
