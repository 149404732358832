<template>
  <div class="r-scale">
    <div
      v-for="(item, ndx) in data"
      :key="ndx"
      :title="getTitle(item)"
      :style="{
        width: getPart(item.value),
        backgroundColor: item.backgroundColor
      }"
      :class="['r-scale__status-block', { animation }]"
    />
  </div>
</template>

<script setup>
// eslint-disable-next-line no-undef
const props = defineProps({
  data: {
    type: Array,
    required: true
  },
  animation: {
    type: Boolean,
    default: false
  }
})

const total = props.data.reduce((a, c) => (a += c.value), 0)

const getTitle = ({ value, name }) => {
  let title = `${value} (${getPart(value, true)})`

  if (name) title = `${name}: ` + title

  return title
}

const getPart = (value, round) => {
  const width = (value / total) * 100

  return `${round ? Math.round(width) : width}%`
}
</script>

<style lang="scss" scoped>
.r-scale {
  width: 100%;
  height: 6px;
  border-radius: 2px;
  display: flex;
  align-items: flex-end;
  overflow: hidden;

  &__status-block {
    height: 100%;

    &.animation {
      background: repeating-linear-gradient(
        -45deg,
        $accent-primary-2,
        $accent-primary-2 15%,
        $accent-primary-1 15%,
        $accent-primary-1 50%
      );
      background-size: 100px 100px;
      animation: move-it 5s linear infinite;
    }
  }
}

@keyframes move-it {
  0% {
    background-position: initial;
  }
  100% {
    background-position: 100px 0px;
  }
}
</style>
