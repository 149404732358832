export const structure = {
  roles: null,
  activeModule: 'users',
  endpoints: null,
  modules: null,
  moduleStatuses: null,
  endpointsStatuses: null,
  checkedUserId: null,
  checkedRoleId: null
}

export const nonClearableFields = ['modules']
