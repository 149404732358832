import vue from 'vue'
import COLORS from 'COLORS'
import { structure, nonClearableFields } from './structure'
import { cloneDeep } from 'HELPERS'

export default {
  state: {
    ...cloneDeep(structure)
  },
  getters: {
    getRoadWorkField: state => field => state[field],
    getRoadWorkMapConfig: state => state.map,
    getRoadWorkSelectedCity: state => state.city,
    getRoadWorkServices: state => state.services,
    getRoadWorkFiltersById: state => id => state.filters[id],
    getRoadWorkServicesById: state => id => state.services[id],
    getRoadWorkStatusColorById: state => id =>
      state.services.statuses?.find(s => s.id === id)?.color ||
      COLORS.root.white
  },
  mutations: {
    ROAD_WORKS_STORE_RESET(state) {
      for (const field in structure) {
        if (nonClearableFields.includes(field)) continue
        state[field] = cloneDeep(structure[field])
      }
    },
    ROAD_WORKS_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        state[field] = value
      }
    }
  }
}
