import { additionalMapLayers } from '@/config/map/layer-styles'
import $store from 'STORE'

export const getPopupLayers = (
  controller,
  isForClick,
  activeLayersName = 'activeLayers'
) => {
  if (!controller?.module) return

  const activeLayers = $store.state[controller?.module]?.[activeLayersName]

  return activeLayers?.reduce((acc, layerId) => {
    const layerStyle = controller?.styleConfig?.[layerId]
    const iconLayerId = `${layerId}-icon`

    if (isForClick || layerStyle?.popup?.enabled) {
      if (layerStyle?.icon && !acc.includes(iconLayerId)) {
        acc.push(iconLayerId)
      }

      acc.push(layerId)

      additionalMapLayers.forEach(({ type }) => {
        acc.push(`${layerId}_${type}`)
      })

      if (layerId === 'links') {
        acc.push('links_side_arrows_polygon')
      }
    }

    return acc
  }, [])
}
