<template>
  <div
    v-if="showTip"
    class="r-tip"
    :class="[type, { center, stretch }]"
  >
    <r-icon
      v-if="icon"
      :name="icon"
      :color="type ? `var(--accent-${type})` : 'var(--text-primary)'"
    />
    <r-text>
      <slot />
    </r-text>
    <r-button
      v-if="closeable"
      simple
      mini
      link
      icon="close-delete"
      type="primary"
      @click="showTip = false"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue'

// eslint-disable-next-line no-undef
const props = defineProps({
  icon: {
    type: String,
    default: null
  },
  center: {
    type: Boolean,
    default: false
  },
  stretch: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: '',
    validator: type =>
      [
        'success',
        'warning',
        'danger',
        'primary',
        'primary-2',
        'info',
        ''
      ].includes(type)
  },
  closeable: {
    type: Boolean,
    default: false
  }
})

const showTip = ref(true)
</script>

<style lang="scss" scoped>
.r-tip {
  display: grid;
  padding: 0.25rem 1rem 0.25rem 0.5rem;
  border-radius: $border-radius;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  justify-content: start;
  justify-self: start;

  &.center {
    justify-self: center;
  }

  &.stretch {
    justify-self: stretch;
  }

  &.success {
    background-color: $accent-success-hover;
  }

  &.warning {
    background-color: $accent-warning-hover;
  }

  &.danger {
    background-color: $accent-danger-hover;
  }

  &.primary {
    background-color: $button-secondary-bg;
  }

  &.primary-2 {
    background-color: $bg-panel-secondary;
  }

  &.info {
    background-color: $field-bg;
  }
}
</style>
