export const structure = {
  map: {},
  tree: [],
  fields: {},
  isSelectDisabled: false,
  layerNames: {},
  layerTypes: {},
  activeLayers: [],
  selectedModelId: null,
  fullDataSet: {},
  objectsCount: {},
  legendWidgetsOnMap: {},
  // pt
  activeTsysCode: null,
  activeLineRouteItems: [],
  ptActiveLayers: []
}

export const nonClearableFields = [
  'layerNames',
  'map',
  'legendWidgetsOnMap',
  'activeLayers'
]
