export const structure = {
  map: {},
  fields: {},
  listSource: null,
  // TODO: refactor: replace card object with card id
  card: null,
  isCreate: false
}

export const nonClearableFields = ['map']
