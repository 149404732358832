export const structure = {
  map: {},
  city: null,
  violationId: null,
  cameraId: null,
  fields: {},
  statuses: [],
  filters: {}
}

export const nonClearableFields = ['city', 'map']
