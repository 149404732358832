<template>
  <div
    class="r-input-number"
    :class="{ mini, disabled }"
  >
    <r-text
      v-if="label"
      color-type="subhead"
    >
      {{ label }}
    </r-text>
    <div class="r-input-number__body">
      <r-button
        v-if="!controls && !noControls"
        class="r-input-number__left"
        :mini="mini"
        icon="minus"
        :disabled="value <= min || disabled"
        @click="decrease"
      />
      <r-button
        v-if="!controls && !noControls"
        class="r-input-number__right"
        icon="add-plus"
        :mini="mini"
        :disabled="value >= max || disabled"
        @click="increase"
      />
      <div
        v-if="controls && !noControls"
        class="r-input-number__controls"
        :class="{ right: controls === 'right' }"
      >
        <r-button
          class="r-input-number__control"
          :class="controlButtonClass"
          tiny-icon
          :icon="{ name: 'chevron-down', rotate: 180 }"
          mini
          :disabled="value >= max || disabled"
          @click="increase"
        />
        <r-button
          class="r-input-number__control"
          :class="controlButtonClass"
          icon="chevron-down"
          tiny-icon
          mini
          :disabled="value <= min || disabled"
          @click="decrease"
        />
      </div>
      <input
        ref="inputNumber"
        :class="['r-input-number__input', { error: notValid }]"
        :placeholder="placeholder"
        :value="value"
        :min="min"
        :max="max"
        :step="inputStep"
        :autocomplete="autocomplete"
        :disabled="disabled"
        type="text"
        @input="input"
        @change="change"
        @keyup.enter="enter"
        @keypress.enter="onEnterPress"
        @focus="emit('focus')"
        @blur="blur"
      />
      <r-text
        v-if="measure"
        color-type="secondary"
        class="r-input-number__measure"
      >
        {{ measure }}
      </r-text>
    </div>
  </div>
</template>

<script setup>
import { isNumber } from 'HELPERS'
import { computed, ref, watch } from 'vue'
// eslint-disable-next-line no-undef
const emit = defineEmits(['input', 'enter', 'focus', 'blur'])

// eslint-disable-next-line no-undef
const props = defineProps({
  onEnterPress: {
    type: Function,
    default: () => {}
  },
  value: {
    type: [String, Number],
    default: null
  },
  placeholder: {
    type: [String, Number],
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  autocomplete: {
    type: String,
    default: 'on'
  },
  mini: {
    type: Boolean,
    default: false
  },
  step: {
    type: Number,
    default: null
  },
  max: {
    type: Number,
    default: undefined
  },
  min: {
    type: Number,
    default: undefined
  },
  controls: {
    // left/right
    type: String,
    default: ''
  },
  noControls: {
    type: Boolean,
    default: false
  },
  canBeNull: {
    type: Boolean,
    default: false
  },
  measure: {
    type: String,
    default: ''
  }
})

const inputNumber = ref(null)
const inputStep = ref(props.step || 1)
const numberValue = computed(() => props.value)

const notValid = computed(
  () =>
    props.error ||
    (((isNumber(props.min) && props.value < props.min) ||
      (isNumber(props.max) && props.value > props.max)) &&
      props.value !== null &&
      props.value !== undefined)
)

const controlButtonClass = computed(() => {
  return [
    { left: props.controls === 'left' },
    { right: props.controls === 'right' }
  ]
})

const enter = event => {
  emit('enter', event)
}

const _toFixed = x => {
  if (Math.abs(x) < 1.0) {
    const e = parseInt(x.toString().split('e-')[1])

    if (e) {
      x *= Math.pow(10, e - 1)
      x = '0.' + new Array(e).join('0') + x.toString().substring(2)
    }
  } else {
    let e = parseInt(x.toString().split('+')[1])

    if (e > 20) {
      e -= 20
      x /= Math.pow(10, e)
      x += new Array(e + 1).join('0')
    }
  }

  return x
}

const getPrecision = val => {
  return val.toString().length - Math.floor(val).toString().length - 1
}

const removeExtraDots = str => {
  let parts = str.split('.')

  if (parts.length > 2) {
    const lastIndex = str.lastIndexOf('.')

    if (lastIndex !== -1) {
      str = str.substring(0, lastIndex) + str.substring(lastIndex + 1)
    }
  } else {
    if (!parts[0] && parts[1]) {
      str = 0 + '.' + parts[1]
    }
  }

  return str
}

const input = () => {
  let val = inputNumber.value.value

  if (val.includes('.')) {
    inputNumber.value.value = val = removeExtraDots(val)
  }

  if (['-', '-0', '-0.', '-.'].includes(val)) {
    return
  }

  if ((val.startsWith('-.') || val.startsWith('-0.')) && val.endsWith('0')) {
    return
  }

  if (val.endsWith('.')) {
    return
  }

  val =
    val < 0
      ? String(_toFixed(Number(val.replace(/[^0-9.,e+]/g, '')) * -1)) // TODO: fast hotfix with negative numbers
      : val.replace(/[^0-9.,]/g, '')
  // замена запятой на точку
  val = val.replace(/,/g, '.')

  if (!(val[val.length - 1] === '.') && val) {
    emit('input', _toFixed(Number(val)))
  }

  inputNumber.value.value = val
}

const blur = () => {
  change()
  inputNumber.value.value = props.value

  emit('blur')
}

const change = () => {
  const count = getPrecision(inputNumber.value?.value)

  if (!props.step && inputNumber.value) {
    if (count > 0) {
      inputStep.value = 10 ** (0 - count)
    } else inputStep.value = 1
  }

  if (inputNumber.value.value > props.max) {
    emit('input', props.max)
    inputNumber.value.value = props.max
  } else if (inputNumber.value.value < props.min) {
    emit('input', props.min)
    inputNumber.value.value = props.min
  } else if (inputNumber.value.value === '') {
    emit('input', props.canBeNull ? null : 0)
  }
}

const handleStep = () => {
  if (inputStep.value < 1) {
    inputNumber.value.value = Number(inputNumber.value.value).toFixed(
      getPrecision(inputStep.value)
    )
  }

  input()
  change()
}

const decrease = () => {
  inputNumber.value.value = Number(inputNumber.value.value) - inputStep.value
  handleStep()
}

const increase = param => {
  inputNumber.value.value = Number(inputNumber.value.value) + inputStep.value
  handleStep()
}
</script>

<style lang="scss" scoped>
.r-input-number {
  flex: 1;
  display: grid;
  grid-gap: 0.25rem;
  min-width: 100px;

  &__measure {
    min-height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    right: 12px;
  }

  &__left,
  &__right {
    border-radius: 0;
    position: absolute;
    width: 1.75rem !important;
    padding: 4px;
    border: none;
    height: 100%;
    cursor: pointer;
  }

  &__left {
    left: 0;
  }

  &__right {
    right: 0;
  }

  &__body {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    border-radius: $border-radius;
    overflow: hidden;
  }

  &__input {
    height: 36px;
    border-radius: $border-radius;
    padding: 0 1.75rem;
    transition: border 0.15s ease;
    width: 100%;
    color: $text-primary;
    background: $field-bg;
    @include border($field-border);
    text-align: center;

    &::placeholder {
      color: $field-placeholder;
    }

    &:focus {
      @include border($field-active-border);
    }

    &.error {
      border-color: $accent-danger;
    }
  }

  &__controls {
    width: 18px;
    height: 36px;
    position: absolute;

    &.right {
      right: 0;
    }
  }

  &__control {
    height: 18px !important;
    width: 18px !important;

    &.left {
      @include border-right;

      &:first-child {
        @include border-bottom;
        border-radius: 0;
        border-top-left-radius: 0.25rem;
      }

      &:last-child {
        border-radius: 0;
        border-bottom-left-radius: 0.25rem;
      }
    }

    &.right {
      &:first-child {
        @include border-bottom;
        border-radius: 0;
        border-top-right-radius: 0.25rem;
      }

      &:last-child {
        border-radius: 0;
        border-bottom-right-radius: 0.25rem;
      }
    }
  }

  &.disabled {
    .r-input-number__input {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }

  &.mini {
    .r-input-number__controls {
      height: 28px;
      width: 14px;
    }
    .r-input-number__input {
      padding: 0 0.75rem;
      height: 28px;
    }

    .r-input-number__control {
      height: 14px !important;
      width: 14px !important;
    }
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'],
input[type='number']:hover,
input[type='number']:focus {
  appearance: none;
  -moz-appearance: textfield;
}
</style>
