import vue from 'vue'
import { structure } from './structure'
import { cloneDeep } from 'HELPERS'

export default {
  state: {
    ...cloneDeep(structure)
  },
  getters: {},
  mutations: {
    SCENARIO_STORE_RESET(state) {
      for (const field in structure) {
        state[field] = cloneDeep(structure[field])
      }
    },
    SCENARIO_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        state[field] = value
      }
    }
  }
}
