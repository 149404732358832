import vue from 'vue'
import { cloneDeep } from 'HELPERS'
import { structure, nonClearableFields } from './structure'

export default {
  state: {
    ...cloneDeep(structure)
  },
  getters: {
    getItsDataById: state => (id, field) =>
      state[field].find(item => item.id === id),
    getItsField: state => field => state[field],
    getItsCity: state => state.city
  },
  mutations: {
    ITS_CONTROL_STORE_RESET(state) {
      for (const field in structure) {
        if (nonClearableFields.includes(field)) continue
        state[field] = cloneDeep(structure[field])
      }
    },
    ITS_CONTROL_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')

        if (state[parent][child]) {
          vue.set(state[parent], `${child}`, value)
        } else {
          const updField = cloneDeep(state[parent])
          updField[child] = value
          state[parent] = updField
        }
      } else {
        state[field] = value
      }
    },
    ITS_CONTROL_CLEAR(state, field) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        delete state[parent][child]
      } else {
        delete state[field]
      }
    },
    ITS_CONTROL_ASSIGN(state, { field, key, value }) {
      state[field] = Object.assign({ ...state[field] }, { [key]: value })
    }
  }
}
