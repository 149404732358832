export default {
  getStateField: state => field => state[field],
  getModuleField:
    state =>
    ({ field, module }) =>
      state[module]?.[field],
  getAdditionalGisLayersByModule: state => module =>
    state.additionalGisLayers[module],
  isRouteAvailable: state => routeName => state.availableRoutes.has(routeName),
  getExtraGisLayers: state => state.extraGisLayers,
  getMapConfigByModule: state => module => state[module].map,
  getRegionByModule: state => module => state[module]?.city,
  getMapByModule: state => module => state[module]?.map,
  getProfileVersion: state => state.profileVersion,
  getProfileId: state => state.user?.profile?.id,
  getGeomEditorState: state => state.isDrawing,
  isAuthorized: state => state.authorized,
  getDownloads: state => state.downloads,
  getAbbrState: state => state.abbr,
  getLocale: state => state.locale,
  getTheme: state => state.theme
}
