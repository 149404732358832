export const throttle = (cb, delay) => {
  let w = false
  let a
  let ctx

  function tf() {
    if (a === null) {
      w = false
    } else {
      if (a) cb.call(ctx, ...a)
      else cb.call(ctx)
      a = null

      setTimeout(tf, delay)
    }
  }

  return function (...args) {
    ctx = this

    if (w) {
      a = args || 0
    } else {
      cb.call(this, ...args)

      w = true

      setTimeout(tf, delay)
    }
  }
}
