export default (el, binding) => {
  if (!binding.oldValue && !!binding.value) {
    el.style.pointerEvents = 'none'
    el.style.opacity = 0.4
    el.style.userSelect = 'none'
  } else if (!!binding.oldValue && !binding.value) {
    el.style.pointerEvents = ''
    el.style.opacity = ''
    el.style.userSelect = ''
  }
}
