import NotificationGroup from './notification-group'
// import { provide } from 'vue'

export default {
  install: Vue => {
    const instance = new (Vue.extend(NotificationGroup))({
      el: document.createElement('div')
    })

    const notify = options => {
      instance.addNotification(options)
    }

    Vue.prototype.$notify = notify
  }
}
