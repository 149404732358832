import $store from 'STORE'
import VueI18n from 'vue-i18n'
import messages from '@/config/locale/index'
import Vue from 'vue'

Vue.use(VueI18n)
const locale = $store.getters.getLocale || 'ru'

export default new VueI18n({
  locale,
  fallbackLocale: locale,
  silentTranslationWarn: true,
  messages
})
