import vue from 'vue'
import { structure, nonClearableFields } from './structure'
import { cloneDeep } from 'HELPERS'

export default {
  state: {
    ...cloneDeep(structure)
  },
  getters: {
    getEmergencySelectedCity: state => state.city,
    getEmergencyStatusSlugById: state => (id, type) =>
      state.statuses?.[type]?.find(status => status.id === id)?.slug || 'none',
    getEmergencyEventType: state => id =>
      state.services.eventTypes.find(type => type.id === id)
  },
  mutations: {
    EMERGENCY_STORE_RESET(state) {
      for (const field in structure) {
        if (nonClearableFields.includes(field)) continue
        state[field] = cloneDeep(structure[field])
      }
    },
    EMERGENCY_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        state[field] = value
      }
    }
  }
}
