<template>
  <div
    class="r-slider"
    :class="{ 'from-center': fromCenter }"
  >
    <r-text
      v-if="label"
      color-type="subhead"
    >
      {{ label }}
    </r-text>
    <div
      class="r-slider__wrapper"
      :class="{ 'no-input': noInput }"
    >
      <div class="r-slider__input-wrapper">
        <input
          v-model="input"
          type="range"
          :style="{ backgroundSize: !fromCenter ? interval : null }"
          :min="min"
          :max="max"
          :disabled="disabled"
          :step="rangeStep"
          @input="updateInput"
        />
        <div
          v-if="fromCenter"
          class="r-slider__active-interval-from-center"
          :style="activeIntervalStyle"
        />
        <div
          v-if="fromCenter"
          class="r-slider__marks"
        >
          <div
            :style="{ left: getScalePoint(0) }"
            class="r-slider__center-mark"
          >
            <span />
            <r-text> 0 </r-text>
          </div>
        </div>
      </div>

      <r-input-number
        v-if="!noInput"
        v-model="input"
        :min="min"
        :max="max"
        :measure="measure"
        :no-controls="noControls"
        :disabled="disabled"
        mini
        :step="step"
        controls="right"
        class="r-slider__input"
        @input="updateInput"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from 'HELPERS'
import { ref, computed } from 'vue'

export default {
  props: {
    label: {
      type: String,
      default: null
    },
    value: {
      type: Number,
      default: 0
    },
    noInput: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 9
    },
    step: {
      type: Number,
      default: null
    },
    noControls: {
      type: Boolean,
      default: false
    },
    measure: {
      type: String,
      default: null
    },
    // for offset (tsodd lanes)
    fromCenter: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const input = ref(props.value)
    const interval = computed(() => {
      return (
        ((input.value - props.min) * 100) / (props.max - props.min) + '% 100%'
      )
    })

    const activeIntervalStyle = computed(() => {
      const percentagesFromStart =
        ((input.value - props.min) * 100) / (props.max - props.min)
      const left = input.value > 0 ? `50%` : `${percentagesFromStart}%`
      const right = input.value > 0 ? `${100 - percentagesFromStart}%` : `50%`

      return {
        left,
        right
      }
    })

    const rangeStep = computed(() => {
      if (!props.max && props.max !== 0) return props.step

      const arr = props.max.toString().split('.')

      if (arr?.length <= 1) return props.step

      const stepArr = (props.step || 1).toString().split('.')
      const stepCount = stepArr[1]?.length || 0
      const count = arr[1]?.length || 0

      if (stepCount >= count) return props.step

      return 1 / 10 ** count
    })

    const getScalePoint = value => {
      const fromStart = value - props.min
      const percent = (fromStart * 100) / (props.max - props.min)

      // const corrector = fromStart / props.max

      // return `calc(${procent}% - ${corrector}px)`
      return `${percent}%`
    }

    const updateInput = function (e) {
      let val = e

      if (typeof e === 'object') {
        let target = e.target

        if (e.target.type !== 'range') {
          target = document.getElementById('range')
        }

        val = +target.value
      }

      return debounce(emit('change', +val), 250)
    }

    return {
      rangeStep,
      input,
      interval,
      activeIntervalStyle,
      getScalePoint,
      updateInput
    }
  },
  watch: {
    // i added it for updating slider value on active route option change (dt pt)
    value(val) {
      this.input = val
    }
  }
}
</script>

<style lang="scss" scoped>
.r-slider {
  display: grid;
  grid-gap: 0.5rem;
  width: 100%;

  &.from-center {
    .r-slider__wrapper {
      padding-bottom: 10px;
    }

    .r-slider__input-wrapper {
      position: relative;

      input[type='range'] {
        width: 100%;
        height: 2px;
        border-radius: 0.25rem;
        cursor: pointer;
        background-image: unset;
        position: relative;
        z-index: 1;
      }
    }

    .r-slider__active-interval-from-center {
      position: absolute;
      left: 50%;
      background: $accent-primary;
      right: 25%;
      height: 2px;
      pointer-events: none;
    }
  }

  &__wrapper {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr auto;
    align-items: center;

    &.no-input {
      grid-template-columns: auto;
    }
  }

  &__input {
    width: 100px;
  }

  &__input-wrapper {
    display: grid;
    align-items: center;
  }

  &__marks {
    position: absolute;
    top: -6px;
    left: 0;
    width: 100%;
    height: 100%;
    user-select: none;
    pointer-events: none;

    span {
      display: block;
      width: 1px;
      height: 14px;
      z-index: 1;
      background-color: $text-primary;
    }

    .r-text {
      white-space: nowrap;

      &.vertical {
        writing-mode: vertical-rl;
      }
    }
  }

  &__center-mark {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%);
  }

  @mixin thumb {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: $accent-primary;
  }

  input[type='range']:not([disabled]):active::-webkit-slider-thumb {
    cursor: grabbing;
  }

  input[type='range']:not([disabled]):active::-moz-range-thumb {
    cursor: grabbing;
  }

  input[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 2px;
    background-color: $dividers-low-contrast;
    border-radius: 0.25rem;
    background-image: linear-gradient($accent-primary, $accent-primary);
    background-repeat: no-repeat;
    cursor: pointer;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    @include thumb;
    cursor: grab;
  }

  input[type='range']::-moz-range-thumb {
    -moz-appearance: none;
    border: none;
    @include thumb;
    cursor: grab;
  }

  input[disabled],
  input[disabled]::-webkit-slider-thumb {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
</style>
