import rDate from 'ritm-date'

export const getDatesAgo = (daysAgo = -1) => {
  /**
   * Returns dates - the current and the previous one by the number of days transferred
   * @param {number} daysAgo - the number of days ago with a minus sign
   * @return {array} Format ISO dates - current date and date ago
   * Example for month:
   * From: '2023-03-03T00:00:00+03:00'
   * To: '2023-04-03T23:59:00+03:00'
   */
  const date = new Date()

  const from = rDate(date).zeroing().calc(daysAgo, 'days').format('iso')
  const to = rDate(date)
    .zeroing()
    .calc(23, 'hours')
    .calc(59, 'minutes')
    .format('iso')

  return [from, to]
}

export const getDaysOfMonths = (count = 1) => {
  /**
   * Returns the number of the total number of days of the transferred months
   * @param {number} count Months count
   * @return {number} Total number of days
   */

  const date = new Date()
  const monthsAgo = new Date()

  monthsAgo.setMonth(date.getMonth() - count)

  const timeDifference = date.getTime() - monthsAgo.getTime()

  return Math.floor(timeDifference / (1000 * 3600 * 24))
}

export const dateIsoFormat = (dateFrom, dateTo) => {
  const from = rDate(dateFrom).zeroing().format('iso')
  const to = rDate(dateTo)
    .zeroing()
    .calc(23, 'hours')
    .calc(59, 'minutes')
    .format('iso')

  return [from, to]
}
