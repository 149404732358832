export default (el, binding) => {
  if (!binding.oldValue && binding.value) {
    el.style.cssText += ';position: relative !important;'
    const div = document.createElement('div')
    div.className = 'r-loading__mask'
    el.appendChild(div)
  } else if (binding.oldValue && !binding.value) {
    if (el.lastChild.className === 'r-loading__mask') {
      el.lastChild.remove()
      el.style.cssText = el.style.cssText.replace(
        'position: relative !important;',
        ''
      )
    }
  }
}
