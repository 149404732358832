// Main
import Vue from 'vue'
import store from './store'
import App from './App.vue'
import router from './router'
import i18n from './extensions/i18n'

// Libs
import PortalVue from 'portal-vue'

// Styles
import './assets/styles/main.scss'
import 'maplibre-gl/dist/maplibre-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import SvgSprite from 'vue-svg-sprite'

// Ritm-UI
import '@/r-ui'
import Dialog from '@/extensions/dialog'
import Notification from '@/extensions/notification'

// Directives
import badge from './extensions/badger'
import loader from './extensions/loader'
import disabler from './extensions/disabler'
import skeleton from './extensions/skeleton'
import clickOutside from './extensions/click-outside'

// Date formatter
import RitmDate from 'ritm-date'

// Other
import { URL } from '@/config/constants'
import request from 'API'
import userController from './extensions/user-controller-definer'

//  -----*-----*-----*-----*-----*-----*-----*-----*-----*-----*-----  //

const rDateMixin = {
  data() {
    return { ...request, URL }
  },
  methods: {
    $rDate(date) {
      return new RitmDate(date).setLocale('ru')
    }
  }
}

Vue.mixin(rDateMixin)

Vue.directive('loading', loader)
Vue.directive('skeleton', skeleton)
Vue.directive('badge', badge)
Vue.directive('disabled', disabler)
Vue.directive('click-outside', clickOutside)

Vue.use(PortalVue) // must be first use

Vue.use(Dialog)
Vue.use(Notification)
Vue.use(userController)
Vue.use(SvgSprite, {
  url: '/sprite/symbol/main.svg',
  class: ''
})

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
