<template>
  <div
    class="r-circle-progress"
    :style="{ width: `${size}px`, height: `${size}px` }"
  >
    <svg>
      <circle
        v-for="item in ['shadow', 'main']"
        :key="item"
        width="100%"
        height="100%"
        fill="transparent"
        :r="getStyle(item, 'r')"
        :cx="getStyle(item, 'cx')"
        :cy="getStyle(item, 'cy')"
        :stroke="getStyle(item, 'stroke')"
        :stroke-dasharray="getStyle(item, 'strokeDasharray')"
        :stroke-width="thickness"
      />
    </svg>
    <div
      v-if="value >= 0"
      class="r-circle-progress__count r-text"
      :style="{ fontSize: `${textSize}px` }"
    >
      {{ value }}
    </div>
    <div
      v-else
      class="r-circle-progress__count r-text"
      :style="{ fontSize: `${textSize - 2}px` }"
    >
      {{ $t('n_a') }}
    </div>
  </div>
</template>

<script setup>
import { getTheme } from 'HELPERS'
import COLORS from 'COLORS'

// eslint-disable-next-line no-undef
const props = defineProps({
  size: {
    type: Number,
    default: 50
  },
  textSize: {
    type: Number,
    default: 14
  },
  thickness: {
    type: Number,
    default: 5
  },
  fullValue: {
    type: Number,
    default: 100
  },
  value: {
    type: Number,
    required: true
  },
  color: {
    type: String,
    default: 'var(--accent-primary)'
  }
})

const getStyle = (type, attr) => {
  const { size, thickness, color, value, fullValue } = props
  const start = size / 2
  const radius = start - thickness / 2 - 1
  const part = value / fullValue
  const dashArr = `${part * 6.283 * radius} ${6.283 * radius}`

  const style = {
    r: radius,
    cx: start,
    cy: start,
    strokeWidth: thickness,
    stroke:
      type === 'main' && value >= 0
        ? color
        : COLORS[getTheme()].iconsLowContrast,
    strokeDasharray: type === 'main' ? dashArr : ''
  }

  return style[attr]
}
</script>

<style lang="scss" scoped>
.r-circle-progress {
  position: relative;

  svg {
    transform: rotate(-90deg);
    width: 100%;
    height: 100%;
  }

  &__count {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
