export const debounce = (cb, d) => {
  let t

  return function (...args) {
    clearTimeout(t)

    const ctx = this

    t = setTimeout(() => {
      cb.call(ctx, ...args)
    }, d)
  }
}
