import vue from 'vue'
import { cloneDeep } from 'HELPERS'
import { structure, nonClearableFields } from './structure'

export default {
  state: {
    ...cloneDeep(structure)
  },
  getters: {
    getLicensesActiveType: state => state.activeType
  },
  mutations: {
    LICENSES_STORE_RESET(state) {
      for (const field in structure) {
        if (nonClearableFields.includes(field)) continue
        state[field] = cloneDeep(structure[field])
      }
    },
    LICENSES_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')

        if (state[parent][child]) {
          vue.set(state[parent], `${child}`, value)
        } else {
          const updField = cloneDeep(state[parent])
          updField[child] = value
          state[parent] = updField
        }
      } else {
        state[field] = value
      }
    }
  }
}
