export const isEmail = string => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  return pattern.test(string)
}

export const isLatin = string => {
  const pattern = /^[a-z]+([_.]?[a-z0-9]+)*$/i

  return pattern.test(string)
}

export const isValidDbColName = string => {
  const pattern = /^[A-ZА-Я][A-ZА-Я0-9_-]*$/i

  return pattern.test(string)
}

export const isLatinWithDot = string => {
  const pattern = /^[a-z]+([_.]?[a-z0-9]+)*$/i

  return pattern.test(string)
}

export const isPassword = string => {
  const pattern = /^[a-zA-Z0-9_%*)(^&?=@+!#$~]+$/

  return pattern.test(string)
}
// const pattern = /^[a-zA-Z0-9_%*)(^&?=@+!#$~]+$/

export const hasCyrillic = string => {
  const pattern = /[а-яА-ЯЁё]/

  return pattern.test(string)
}

export const numberLessThan = (num, min) => {
  return num <= min
}

export const numberMoreThan = (num, max) => {
  return num >= max
}

export const minStringLength = (arg, min) => {
  return arg?.length >= min
}

export const maxStringLength = (arg, max) => {
  return arg?.length <= max
}

export const stringNotEmpty = string => {
  return !!string?.trim()
}

export const isPhone = string => {
  const pattern =
    /^\+?\d{1,3}\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{2}[-.\s]?\d{2}$/

  return pattern.test(string)
}
