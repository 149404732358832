export const structure = {
  map: {},
  city: null,
  fields: {},
  objectCard: null,
  objectEditor: null,
  activeLayers: [],
  hasUnsavedData: false,
  layerObjectList: null,
  layerNames: {}
}

export const nonClearableFields = ['map']
