<template>
  <div
    :class="['r-icon', `${colorType}`, { loading }]"
    :style="`${arrowDown ? 'grid-gap: 0' : ''};transform: rotate(${rotate}deg)`"
  >
    <svg
      v-if="name"
      v-svg
      :symbol="name"
      role="presentation"
      class="r-icon__svg"
      :style="getStyle()"
    />
    <span
      v-if="loading"
      class="r-icon__spinner"
    />
    <div
      v-if="arrowDown"
      class="r-icon-arrow-down"
      :style="getArrowStyle"
    />
  </div>
</template>

<script>
const colorTypes = [
  'danger',
  'primary',
  'info',
  'contrast',
  'warning',
  'white',
  'error',
  'success',
  ''
]

export default {
  props: {
    name: {
      type: String,
      default: null
    },
    size: {
      type: Number,
      default: 22
    },
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    },
    color: {
      type: String,
      default: ''
    },
    arrowDown: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    rotate: {
      type: Number,
      default: 0
    },
    colorType: {
      validator: type => colorTypes.includes(type),
      default: ''
    },
    label: {
      type: String,
      default: null
    }
  },
  computed: {
    getArrowStyle() {
      const styles = {}

      if (this.color) styles.background = `${this.color} !important`

      return styles
    }
  },
  methods: {
    getStyle() {
      const styles = {}

      if (this.color) styles.fill = `${this.color} !important`

      if (this.size) {
        styles.width = `${this.size}px`
        styles.height = `${this.size}px`
      }

      if (this.width) {
        styles.width = `${this.width}px`
      }

      if (this.height) {
        styles.height = `${this.height}px`
      }

      return styles
    }
  }
}
</script>

<style lang="scss">
.r-icon {
  fill: $icons-high-contrast !important;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: auto;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  &__svg {
    width: 100%;
    height: 100%;
    transition: all 0.15s ease;
  }

  &.loading {
    .r-icon__svg {
      opacity: 0;
    }
  }

  &__spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: ' ';
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      border: 2px solid;
      border-color: $icons-high-contrast transparent $icons-high-contrast
        transparent;
      animation: spin 1.25s infinite cubic-bezier(0.5, 0, 0.5, 1);
    }
  }

  .r-title {
    margin-left: 2px;
  }

  &-arrow-down {
    margin-left: 0.25rem;
    width: 0.5rem;
    height: 0.25rem;
    clip-path: polygon(0 0, 50% 100%, 100% 0);
    background-color: $icons-high-contrast !important;
  }

  &.danger,
  &.error {
    .r-icon__svg {
      fill: $accent-danger !important;
    }

    .r-title.subhead {
      color: $accent-danger !important;
    }

    .r-icon-arrow-down {
      background-color: $accent-danger !important;
    }
  }

  &.primary,
  &.info {
    .r-icon__svg {
      fill: $accent-primary !important;
    }

    .r-title.subhead {
      color: $accent-primary !important;
    }

    .r-icon-arrow-down {
      background-color: $accent-primary !important;
    }
  }

  &.contrast {
    .r-icon__svg {
      fill: $text-primary !important;
    }

    .r-title.subhead {
      color: $text-primary !important;
    }

    .r-icon-arrow-down {
      background-color: $text-primary !important;
    }
  }

  &.success {
    .r-icon__svg {
      fill: $accent-success !important;
    }

    .r-title.subhead {
      color: $accent-success !important;
    }

    .r-icon-arrow-down {
      background-color: $accent-success !important;
    }
  }

  &.warning {
    .r-icon__svg {
      fill: $accent-warning !important;
    }

    .r-title.subhead {
      color: $accent-warning !important;
    }

    .r-icon-arrow-down {
      background-color: $accent-warning !important;
    }
  }

  &.white {
    .r-icon__svg {
      fill: $white !important;
    }

    .r-title.subhead {
      color: $white !important;
    }

    .r-icon-arrow-down {
      background-color: $white !important;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1079deg);
  }
}
</style>
