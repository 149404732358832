import UserController from '@/modules/user/helpers/user-controller'

export default {
  install(Vue) {
    const userController = new UserController()

    Vue.prototype.$userController = userController
    Vue.$userController = userController
  }
}
