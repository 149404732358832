<template>
  <div
    class="r-checkbox-group"
    @change="handleChange"
  >
    <r-checkbox
      v-if="hasSelectAll"
      :checked="allChecked"
      :indeterminate="indeterminate"
      @click="handleSelectAllChange"
    >
      <r-text> &#60;{{ $t('select_all') }}&#62; </r-text>
    </r-checkbox>
    <!--  checkboxes in checkbox group slot must have IDs-->
    <slot />
  </div>
</template>

<script>
export default {
  name: 'RCheckboxGroup',
  fieldTypes: ['boolean', 'decimal', 'time', 'numeric', 'integer', 'string'],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    hasSelectAll: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      indeterminate: false,
      allChecked: false,
      activeDisabledIds: []
    }
  },
  watch: {
    value() {
      this.setCheckboxesValues()
      this.setAllChecked()
    }
  },
  mounted() {
    this.activeDisabledIds = this.$children
      .filter(
        option => option.disabled && option.id && this.value.includes(option.id)
      )
      .map(item => item.id)
    this.setCheckboxesValues(true)
    this.setAllChecked()
  },
  methods: {
    setIndeterminate() {
      const allChecked = this.$children
        .filter(option => !option.disabled && option.id)
        .map(item => item.id)
        .every(option => this.value.includes(option))

      const allUnChecked = this.$children
        .filter(option => !option.disabled && option.id)
        .map(item => item.id)
        .every(option => !this.value.includes(option))
      this.indeterminate = !!this.value.length && !allChecked && !allUnChecked
    },
    setAllChecked() {
      this.allChecked = this.$children
        .filter(option => !option.disabled && option.id)
        .map(item => item.id)
        .every(option => this.value.includes(option))
    },
    setCheckboxesValues(firstRender) {
      if (firstRender) {
        this.$children
          .filter(child => child.id)
          .forEach(child => (child.isChecked = this.value.includes(child.id)))
      } else {
        this.$children
          .filter(child => child.id && !child.disabled)
          .forEach(child => (child.isChecked = this.value.includes(child.id)))
      }

      this.setIndeterminate()
    },
    handleChange(evt) {
      if (!evt.target.id) return

      const targetCheckbox = this.$children.find(
        child => child.id === evt.target.id
      )

      if (targetCheckbox.disabled) return
      targetCheckbox.isChecked = !targetCheckbox.isChecked
      const ids = this.$children
        .filter(child => child.id && child.isChecked)
        .map(child => child.id)

      this.$emit('change', [
        ...this.activeDisabledIds,
        ...ids.filter(id => !this.activeDisabledIds.includes(id))
      ])
    },
    handleSelectAllChange() {
      this.indeterminate = false

      if (this.allChecked)
        return this.$emit('change', [...this.activeDisabledIds])

      const ids = this.$children
        .filter(child => child.id && !child.disabled)
        .map(child => child.id)

      this.$emit('change', [...this.activeDisabledIds, ...ids])
    }
  }
}
</script>

<style lang="scss" scoped>
.r-checkbox-group {
  @include grid-column(0.25rem);
}
</style>
