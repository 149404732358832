import vue from 'vue'
import { structure, nonClearableFields } from './structure'
import { cloneDeep } from 'HELPERS'

export default {
  state: {
    ...cloneDeep(structure)
  },
  getters: {
    getPTStopId: state => state.stopId,
    getPTRouteId: state => state.routeId,
    getPTvehicleId: state => state.vehicleId,
    getPTcity: state => state.city,
    getPTField: state => field => state[field],
    getPTrouteColor: state => state.routeColor,
    getPTfields: state => state.fields,
    getPTBoardsId: state => state.boardsId,
    getPTvehicleTypes: state => state.vehicleTypes
  },
  mutations: {
    PUBLIC_TRANSPORT_STORE_RESET(state) {
      for (const field in structure) {
        if (nonClearableFields.includes(field)) continue
        state[field] = cloneDeep(structure[field])
      }
    },
    PUBLIC_TRANSPORT_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        state[field] = value
      }
    },
    PUBLIC_TRANSPORT_PUSH(state, [field, value]) {
      state[field].push(value)
    },
    PUBLIC_TRANSPORT_SPLICE(state, [field, value]) {
      const index = state[field].indexOf(value)

      if (index >= 0) state[field].splice(index, 1)
    }
  }
}
