<template>
  <component
    :is="componentType[type]"
    :class="{ tip: type === 'hover' }"
    :name="icon"
    :title="title"
    :menu-max-width="menuMaxWidth"
    :stick-to="stickTo"
  >
    <r-button
      v-if="type === 'click'"
      :icon="icon"
      :mini="mini"
    />
    <div
      v-if="type === 'click'"
      slot="dropdown-menu"
    >
      <r-block>
        <r-text>
          {{ title }}
        </r-text>
      </r-block>
    </div>
  </component>
</template>

<script setup>
// eslint-disable-next-line no-undef
const props = defineProps({
  type: {
    type: String,
    default: 'click'
  },
  title: {
    type: String,
    default: ''
  },
  icon: {
    type: String,
    default: 'help'
  },
  menuMaxWidth: {
    type: Number,
    default: 180
  },
  mini: {
    type: Boolean,
    default: false
  },
  stickTo: {
    type: String,
    default: 'left'
  }
})

const componentType = {
  hover: 'r-icon',
  click: 'r-dropdown'
}
</script>

<style lang="scss" scoped>
.tip:hover {
  cursor: help;
}
</style>
