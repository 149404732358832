// Module alias
export const INFO_MODULE = 'info'
export const INFO_ENDPOINT = 'mciud'

// Main alias url
export const ROOT_ENDPOINT = 'mciud'

// Data types
// Info tables
export const INFO_TABLES = 'info_tables'
export const SOURCE_INFO_TABLES = INFO_TABLES
export const LAYER_INFO_TABLES_ICON = `${SOURCE_INFO_TABLES}_icon`
export const LAYER_INFO_TABLES_CIRCLE = `${SOURCE_INFO_TABLES}_circle`

// Dits
export const DITS = 'dits'
export const SOURCE_DITS = DITS
export const LAYER_DITS_ICON = `${SOURCE_DITS}_icon`
export const LAYER_DITS_CIRCLE = `${SOURCE_DITS}_circle`

// ZPIS
export const ZPIS = 'zpis'
export const SOURCE_ZPIS = ZPIS
export const LAYER_ZPIS_ICON = `${SOURCE_ZPIS}_icon`
export const LAYER_ZPIS_CIRCLE = `${SOURCE_ZPIS}_circle`

// Base layers
export const SOURCE = 'info'
export const LAYER_ICON = 'icon'
export const LAYER_CIRCLE = 'circle'

// Create new object
export const CREATE_NEW_OBJECT = 'create_new_object'

// Editor card tabs
export const TAB_INFO = 'info'
export const TAB_SCENARIOS = 'scenarios'

// Supported files for upload content
export const SUPPORTED_IMAGE_FILE_TYPES = [
  '.jpg',
  '.jpeg',
  '.png',
  '.webp',
  '.gif',
  '.bmp'
]

export const SUPPORTED_VIDEO_FILE_TYPES = ['.mp4', '.mpeg', '.ogg', '.webm']

export const SUPPORTED_FILE_TYPES = [
  ...SUPPORTED_IMAGE_FILE_TYPES,
  ...SUPPORTED_VIDEO_FILE_TYPES
]

// Groups mode
export const WITHOUT_GROUP = 'groupless'
export const TAB_LIST = 'list'
export const TAB_CONTENT = 'content'
