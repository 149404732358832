<template>
  <span
    class="r-divider"
    :class="{ vertical }"
  />
</template>

<script>
export default {
  props: {
    vertical: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.r-divider {
  display: grid;
  @include border-bottom;

  &.vertical {
    height: 100%;
    @include border-right;
    border-bottom: none;
  }
}
</style>
