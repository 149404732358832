export const paintSettings = {
  line: [
    'line-translate-anchor',
    'line-trim-offset',
    'line-translate',
    'line-dasharray',
    'line-gap-width',
    'line-gradient',
    'line-opacity',
    'line-pattern',
    'line-offset',
    'line-color',
    'line-width',
    'line-blur'
  ],
  fill: [
    'fill-antialias',
    'fill-color',
    'fill-opacity',
    'fill-outline-color',
    'fill-pattern',
    'fill-translate',
    'fill-translate-anchor'
  ],
  circle: [
    'circle-blur',
    'circle-color',
    'circle-opacity',
    'circle-pitch-alignment',
    'circle-pitch-scale',
    'circle-radius',
    'circle-stroke-color',
    'circle-stroke-opacity',
    'circle-stroke-width',
    'circle-translate',
    'circle-translate-anchor'
  ]
}

export const layoutSettings = {
  line: [
    'line-round-limit',
    'line-miter-limit',
    'line-sort-key',
    'visibility',
    'line-join',
    'line-cap'
  ],
  fill: ['visibility', 'fill-sort-key'],
  circle: ['circle-sort-key', 'visibility']
}
