<template>
  <div
    class="r-text"
    :class="[type, colorType, { center }]"
    :style="getStyle()"
  >
    <slot />
  </div>
</template>

<script>
const textTypes = ['default', 'caption']
const colorTypes = [
  'danger',
  'primary',
  'warning',
  'subhead',
  'secondary',
  'field-placeholder',
  'white',
  'success',
  ''
]

export default {
  props: {
    type: {
      validator: value => textTypes.includes(value),
      default: 'default'
    },
    color: {
      type: String,
      default: null
    },
    noWrap: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    weight: {
      type: Number,
      default: null
    },
    colorType: {
      validator: type => colorTypes.includes(type),
      default: ''
    }
  },
  methods: {
    getStyle() {
      const styles = {}

      if (this.color) styles.color = `${this.color} !important`

      if (this.noWrap) styles['white-space'] = 'nowrap'

      if (this.weight) styles['font-weight'] = `${this.weight} !important`

      return styles
    }
  }
}
</script>

<style lang="scss">
.r-text {
  font-size: 14px;
  color: $text-primary !important;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;

  &.caption {
    font-size: 12px;
    color: $text-secondary !important;

    &.primary {
      color: $text-primary !important;
    }

    &.danger {
      color: $accent-danger !important;
    }

    &.warning {
      color: $accent-warning !important;
    }

    &.success {
      color: $accent-success !important;
    }

    &.subhead {
      color: $text-subhead !important;
    }

    &.secondary {
      color: $text-secondary !important;
    }

    &.white {
      color: $white !important;
    }
  }

  &.center {
    text-align: center;
  }

  &.danger {
    color: $accent-danger !important;
  }

  &.field-placeholder {
    color: $field-placeholder !important;
  }

  &.primary {
    color: $accent-primary !important;
  }

  &.warning {
    color: $accent-warning !important;
  }

  &.success {
    color: $accent-success !important;
  }

  &.subhead {
    color: $text-subhead !important;
  }

  &.secondary {
    color: $text-secondary !important;
  }

  &.white {
    color: $white !important;
  }
}
</style>
