<template>
  <div
    class="r-tooltip__wrapper"
    @mouseenter="!clickTrigger ? handleTrigger($event) : () => null"
    @mouseleave="!clickTrigger ? closeTooltip() : () => null"
    @click="clickTrigger ? handleTrigger($event) : () => null"
  >
    <slot />
  </div>
</template>

<script>
import { debounce } from 'HELPERS'
const triggers = ['hover', 'click']
const places = ['top', 'bottom', 'left', 'right']

export default {
  props: {
    delay: {
      type: Number,
      default: 500
    },
    text: {
      type: String,
      required: true
    },
    place: {
      type: String,
      validator: place => places.includes(place),
      default: 'top'
    },
    trigger: {
      type: String,
      validator: trigger => triggers.indexOf(trigger) > -1,
      default: 'hover'
    }
  },
  data() {
    return {
      tooltipElement: null,
      open: false
    }
  },
  computed: {
    clickTrigger() {
      return this.trigger === 'click'
    },
    updatedText() {
      return this.text.length > 100
        ? `${this.text.slice(0, 100)}...`
        : this.text
    }
  },
  methods: {
    handleTrigger(event) {
      const openedTooltips = document.querySelectorAll('.r-tooltip')

      if (openedTooltips.length) openedTooltips.forEach(e => e.remove())
      this.open = true
      this.openTooltip(event)
    },
    composedPath(el) {
      var path = []

      while (el) {
        path.push(el)

        if (el.tagName === 'HTML') {
          path.push(document)
          path.push(window)

          return path
        }

        el = el.parentElement
      }

      return el
    },
    openTooltip: debounce(function (event) {
      setTimeout(() => {
        if (!this.open) return

        this.tooltipElement = document.createElement('span')
        this.tooltipElement.classList.add('r-tooltip', this.place)
        this.tooltipElement.innerHTML = `${this.text}`
        document.body.append(this.tooltipElement)

        const path = event?.path || this.composedPath(event.target)
        const tooltipWrapper = path.find(el =>
          [...el.classList].includes('r-tooltip__wrapper')
        )

        const where = tooltipWrapper?.getBoundingClientRect()

        this.setTooltipPosition(where)

        if (this.trigger === 'click') {
          document.body.addEventListener(
            'click',
            () => {
              if (document.querySelectorAll('.r-tooltip').length)
                this.closeTooltip()
            },
            { once: true }
          )
        }
      }, this.delay)
      setTimeout(() => {
        if (!this.open) return
        this.tooltipElement.classList.add('visible')
      }, this.delay)
    }, 100),
    closeTooltip() {
      this.open = false

      if (this.tooltipElement) {
        this.tooltipElement.classList.remove('visible')
        this.tooltipElement.addEventListener(
          'transitionend',
          e => {
            if (this.tooltipElement) this.tooltipElement.remove()
            this.tooltipElement = null
            const openedTooltips = document.querySelectorAll('.r-tooltip')

            if (openedTooltips.length) openedTooltips.forEach(e => e.remove())
          },
          { once: true }
        )
      }
    },
    setTooltipPosition({ top, bottom, right, left, width, height }) {
      const ttStyles = window.getComputedStyle(this.tooltipElement)
      const ttHeight = +ttStyles.height.slice(0, -2)
      const ttWidth = +ttStyles.width.slice(0, -2)
      const { style } = this.tooltipElement
      let posTop, posLeft

      switch (this.place) {
        case 'top':
          posTop = top - (ttHeight + 5)
          posLeft = left + width / 2 - ttWidth / 2
          break
        case 'right':
          posTop = top + height / 2 - ttHeight / 2
          posLeft = right + 5
          break
        case 'bottom':
          posTop = bottom + 5
          posLeft = left + width / 2 - ttWidth / 2
          break
        case 'left':
          posTop = top + height / 2 - ttHeight / 2
          posLeft = left - (ttWidth + 5)
          break
      }

      style.top = `${posTop}px`
      style.left = `${posLeft}px`
    }
  }
}
</script>

<style lang="scss">
$transparent: transparent;

.r-tooltip {
  position: absolute;
  top: 100px;
  left: 500px;
  color: $text-primary;
  background-color: $field-droplist-bg;
  min-width: 0px;
  max-width: 200px;
  border-radius: $border-radius;
  text-align: justify;
  padding: 0.25rem;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  z-index: 3100;
  line-height: 1.4;
  font-size: 12px;
  filter: drop-shadow(2px 2px 5px $modal-overlay);
  opacity: 0;
  transition: opacity 0.33s, visibility 0.15s;

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  &__wrapper {
    cursor: pointer;
  }
}
</style>
