<template>
  <r-select
    v-model="month"
    :error="error"
    :disabled="disabled"
    :options="months"
    @change="$emit('change', $event)"
  />
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import i18n from '@/extensions/i18n'
// eslint-disable-next-line no-undef
const props = defineProps({
  value: {
    type: Number,
    default: null
  },
  error: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const months = [
  { id: 1, title: i18n.t('jan') },
  { id: 2, title: i18n.t('feb') },
  { id: 3, title: i18n.t('mar') },
  { id: 4, title: i18n.t('apr') },
  { id: 5, title: i18n.t('may') },
  { id: 6, title: i18n.t('jun') },
  { id: 7, title: i18n.t('jul') },
  { id: 8, title: i18n.t('aug') },
  { id: 9, title: i18n.t('sep') },
  { id: 10, title: i18n.t('oct') },
  { id: 11, title: i18n.t('nov') },
  { id: 12, title: i18n.t('dec') }
]

const month = ref(props.value)

const propsMonth = computed(() => props.value)

watch(propsMonth, () => {
  month.value = propsMonth.value
})
</script>

<style scoped></style>
