<template>
  <div
    class="r-ritm-tabs__list-item"
    :class="{ active: isActive }"
  >
    <slot v-if="isActive" />
  </div>
</template>

<script setup>
import { computed, inject } from 'vue'

// eslint-disable-next-line no-undef
const props = defineProps({
  tabName: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: null
  },
  icon: {
    type: String,
    default: ''
  },
  id: {
    type: [String, Number],
    required: true
  }
})

const currentId = inject('tabId')

const isActive = computed(() => {
  return currentId.value === props.id ? true : false
})
</script>

<style lang="scss">
.r-ritm-tabs__list-item {
  display: none;

  &:empty {
    display: none !important;
  }

  &.active {
    height: 100%;
    overflow: auto;
    display: grid;
    grid-gap: 0.5rem;
  }
}
</style>
