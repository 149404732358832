import { POST, GET, CONFIG_PUT, setAxiosToken } from 'API'
import $store from 'STORE'
import eventBus, { eventNames } from 'EVENT_BUS'
const { UPDATE_PROFILE } = eventNames
import {
  filterObjectByKeys,
  setCookie,
  getCookie,
  debounce,
  getModelKey
} from 'HELPERS'
import {
  systemSettings,
  mainMapModules,
  defaultPTConfig,
  defaultItsConfig,
  systemSettingsDefault,
  defaultMapModulesConfig,
  serverToFrontModuleRelations
} from '@/config/system-settings'
import localforage from 'LF'

export default class UserController {
  constructor() {
    this.saveConfigInLocalStorage = this.saveConfigInLocalStorage.bind(this)
    this.saveMapConfigInLocalStorage =
      this.saveMapConfigInLocalStorage.bind(this)
    this.saveSystemConfigInLocalStorage =
      this.saveSystemConfigInLocalStorage.bind(this)
    this.saveProfileConfig = this.saveProfileConfig.bind(this)
    this.saveProfileConfig = debounce(this.saveProfileConfig, 64)
  }

  async init() {
    await this.loadUser()
    await this.profileHandling({ init: true })
    await this.defineAvailableCities(true)

    await this.setSystemConfig()
    await this.loadMapConfig()
    await this.setProfileConfig()
    this.startListeners()
  }

  startListeners() {
    eventBus.on(UPDATE_PROFILE, this.saveProfileConfig)
  }

  destroy() {
    eventBus.off(UPDATE_PROFILE, this.saveProfileConfig)
  }

  get currentProfileId() {
    return $store.getters.getProfile.id
  }

  async login({ login, password }) {
    const formData = new FormData()

    formData.append('login', login)
    formData.append('password', password)

    const { data } = await POST('mrir/login', formData)

    this.setToken(data)
  }

  setToken({ token }) {
    if (!token) return

    setCookie('ritmToken', token, 1)
    $store.commit('USER_SET', ['token', token])
    setAxiosToken(token)
  }

  async loadUser() {
    try {
      const [{ data: user }, { data: modules }] = await Promise.all([
        GET('mrir/user', null, { maxAge: 86400 }),
        GET(`mrir/modules`, null, { maxAge: 86400 })
      ])

      $store.commit('MRIR_SET', ['modules', modules])

      const modulePerms = new Map(modules)

      for (let i = 0; i < modules.length; i++) {
        const m = modules[i]
        m && modulePerms.set(m.name, m)
      }

      const usefulUserData = filterObjectByKeys(user, ['profile'])

      for (const key in user.profile.config) {
        delete user.profile.config[key].layerStyles
      }

      $store.commit('USER_SET', ['profile', user.profile])
      $store.commit('USER_SET', ['user', usefulUserData])
      $store.commit('USER_SET', ['permissions.modules', modulePerms])
    } catch (e) {
      throw e
    }
  }

  async loadProfiles() {
    try {
      const { data } = await GET('mrir/profiles')

      return data
    } catch (e) {
      throw e
    }
  }

  async setActiveProfile(id) {
    if (!id) return false

    try {
      const { data } = await POST(`mrir/profiles/${id}/set`)

      return !!data
    } catch (e) {
      throw e
    }
  }

  async updateCurrentProfile() {
    try {
      const { data: profile } = await GET('mrir/profiles/current')

      if (profile) {
        $store.commit('USER_SET', ['profile', profile])
      }
    } catch (e) {
      throw e
    }
  }

  // -----*-----*-----*-----*-----*-----*-----*----- //

  async loadMapConfig() {
    const { storeRelations } = $store.state

    const config = await localforage.getItem('mapConfig')

    for (const module in storeRelations) {
      if (config) {
        const mapConfig = JSON.parse(config)

        if (mapConfig[module]) {
          const resultConfig = mapConfig[module]
          const hasConfig = Object.keys(resultConfig)?.length

          if (hasConfig) {
            $store.commit(storeRelations[module], ['map', resultConfig])
          }
        }
      } else {
        $store.commit(storeRelations[module], ['map', {}])

        this.saveMapConfigInLocalStorage()
      }
    }
  }

  async setSystemConfig() {
    const config = await localforage.getItem('systemConfig')
    const systemConfig = JSON.parse(config)

    for (const key in systemSettings) {
      $store.commit('SYSTEM_SET', [
        systemSettings[key],
        systemConfig[systemSettings[key]] ||
          systemSettingsDefault[systemSettings[key]]
      ])
    }

    if (!systemConfig) {
      this.saveSystemConfigInLocalStorage()
    }
  }

  async defineAvailableCities(withCache) {
    try {
      const { data } = await GET(
        'mrir/regions',
        null,
        !withCache && { maxAge: 0 }
      )

      $store.commit('SYSTEM_SET', ['availableCities', data])
    } catch (e) {
      throw e
    }
  }

  async setRegionConfig() {
    const { storeRelations } = $store.state

    const config = await localforage.getItem('regionConfig')
    const regionConfig = JSON.parse(config || '{}')

    if (Object.keys(regionConfig)?.length) {
      for (const module in regionConfig) {
        if (regionConfig[module]) {
          $store.commit(storeRelations[module], ['city', regionConfig[module]])
        }
      }
    }
  }

  setMapModulesConfig(config) {
    const { storeRelations } = $store.state

    mainMapModules.forEach(m => {
      for (const setting in defaultMapModulesConfig) {
        const value = config[m]?.[setting] || defaultMapModulesConfig[setting]
        $store.commit(storeRelations[m], [setting, value])
      }
    })
  }

  async setPTconfig() {
    const ptConfig = await localforage.getItem('ptConfig')

    for (const setting in defaultPTConfig) {
      $store.commit('PUBLIC_TRANSPORT_SET', [
        setting,
        JSON.parse(ptConfig)?.[setting] || defaultPTConfig[setting]
      ])
    }
  }

  async savePTConfig() {
    const ptConfig = {}

    for (const setting in defaultPTConfig) {
      ptConfig[setting] =
        $store.getters.getPTField(setting) || defaultPTConfig[setting]
    }

    await localforage.setItem('ptConfig', JSON.stringify(ptConfig))
  }

  async saveItsControlConfig() {
    const itsConfig = {}

    for (const setting in defaultItsConfig) {
      itsConfig[setting] =
        $store.getters.getItsField(setting) || defaultItsConfig[setting]
    }

    await localforage.setItem('itsControlConfig', JSON.stringify(itsConfig))
  }

  async setITSconfig() {
    const itsControlConfig = await localforage.getItem('itsControlConfig')

    for (const setting in defaultItsConfig) {
      $store.commit('ITS_CONTROL_SET', [
        setting,
        JSON.parse(itsControlConfig)?.[setting] || defaultItsConfig[setting]
      ])
    }
  }

  async setProfileConfig(passedConfig) {
    const storeConfig = $store.getters.getProfile?.config
    const lfConfig = await localforage.getItem('config')
    let config = passedConfig || lfConfig ? JSON.parse(lfConfig) : storeConfig

    const noConfigInLocalStorage = !config || config === '{}'
    const storeConfigIsNewer =
      storeConfig.system.timestamp > (config?.system?.timestamp || 0)

    if (noConfigInLocalStorage || storeConfigIsNewer) {
      config = storeConfig
    }

    if (!Object.keys(config).length) return

    await this.setRegionConfig(config)
    await this.setPTconfig()
    await this.setITSconfig()
    this.setMapModulesConfig(config)

    await localforage.setItem('config', '')
  }

  async prepareConfig({ moduleName, moduleStyles, fullConfig } = {}) {
    let config

    if (fullConfig) {
      config = fullConfig // layer-options saveConfig
    } else {
      const profile = $store.getters.getProfile
      const { data } = await GET(`mrir/profile/${profile?.id}`)
      config = data?.config
    }

    const preparedMapModulesConfig = {}

    mainMapModules.forEach(m => {
      const moduleConfig = {}

      for (const field in defaultMapModulesConfig) {
        const frontModuleName = serverToFrontModuleRelations[m]

        moduleConfig[field] = $store.getters.getModuleField({
          module: frontModuleName,
          field
        })
      }

      // layer-options saveConfig
      if (moduleName === m && moduleStyles) {
        const modelKey = getModelKey()

        if (modelKey) {
          moduleConfig.layerStyles = {
            ...(config[m].layerStyles || {}),
            [modelKey]: moduleStyles
          }
        } else {
          moduleConfig.layerStyles = moduleStyles
        }
      } else {
        moduleConfig.layerStyles = config[m].layerStyles || {}
      }

      preparedMapModulesConfig[m] = moduleConfig
    })

    return preparedMapModulesConfig
  }

  prepareRegionConfig() {
    const { storeRelations } = $store.state
    const modules = Object.keys(storeRelations)

    return modules.reduce((a, m) => {
      const moduleRegion = $store.getters.getRegionByModule(m)

      if (moduleRegion) {
        a[m] = moduleRegion
      }

      return a
    }, {})
  }

  prepareMapConfig() {
    const { storeRelations } = $store.state
    const modules = Object.keys(storeRelations)

    return modules.reduce((a, m) => {
      const moduleMap = $store.getters.getMapByModule(m)

      if (moduleMap) {
        a[m] = moduleMap
      }

      return a
    }, {})
  }

  async requestForProfileConfig(data) {
    if (this.currentProfileId && data) {
      const url = `mrir/profile/${this.currentProfileId}/config`

      return CONFIG_PUT(url, data)
    }
  }

  async saveProfileConfig(moduleName, moduleStyles) {
    if (!getCookie('ritmToken')) return

    this.saveMapConfigInLocalStorage()
    this.saveSystemConfigInLocalStorage()
    await this.saveItsControlConfig()
    await this.savePTConfig()

    const data = await this.prepareConfig(moduleName, moduleStyles)

    return this.requestForProfileConfig(data)
  }

  async saveMapConfigInLocalStorage() {
    const mapConfig = this.prepareMapConfig()
    const regionConfig = this.prepareRegionConfig()

    if (Object.keys(mapConfig).length) {
      await localforage.setItem('mapConfig', JSON.stringify(mapConfig))
    }

    if (Object.keys(regionConfig).length) {
      await localforage.setItem('regionConfig', JSON.stringify(regionConfig))
    }
  }

  async saveSystemConfigInLocalStorage() {
    const { getStateField } = $store.getters
    let config = {}
    for (let key in systemSettings) {
      config[systemSettings[key]] = getStateField(systemSettings[key])
    }

    if (!Object.keys(config).length) return

    await localforage.setItem('systemConfig', JSON.stringify(config))
  }

  async saveConfigInLocalStorage(e) {
    if (document.visibilityState !== 'visible') return

    const config = await this.prepareConfig()

    config.system = { timestamp: Date.now() }

    // TODO: temporary off
    // if (e?.type === 'visibilitychange') {
    //   const bc = new BroadcastChannel('localforage')
    //   const oldValue = await localforage.getItem('config')
    //   bc.postMessage({ newValue: config, oldValue })
    // }

    await localforage.setItem('config', JSON.stringify(config))
  }

  async profileHandling(
    { init = false, callStackSize = 0 } = { init: false, callStackSize: 0 }
  ) {
    try {
      if (!init) await this.updateCurrentProfile()

      const profile = $store.getters.getProfile

      if (!profile) {
        const all = this.loadProfiles()

        if (all[0]) {
          const setNext = await this.setActiveProfile(all[0].id)

          if (setNext) {
            console.warn('Profile has been changed')

            this.profileHandling({ callStackSize: callStackSize + 1 })
          }
        } else if (callStackSize <= 2) {
          console.warn('No one profile')

          const created = await POST(`mrir/profiles`, {
            name: 'defaut'
          })

          if (created) {
            console.warn('New profile has been created')
          }

          callStackSize += 1
          this.profileHandling({ callStackSize: callStackSize + 1 })
        } else {
          throw new Error('Unable to create user profile')
        }
      }
    } catch (e) {
      throw e
    }
  }
}
