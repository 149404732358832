export const structure = {
  tree: [],
  map: {},
  layerNames: {},
  fullDataSet: {},
  // mainGisFields: {},
  layerTypes: {},
  fields: {},
  selectedModelId: null,
  activeLayers: [],
  isSelectDisabled: false,
  zIndex: {},
  scenarioStatuses: null,
  objectsCount: {},
  legendWidgetsOnMap: {}
  // currentModelId: null,
}

export const nonClearableFields = [
  'map',
  'zIndex',
  'fullDataSet',
  'activeLayers',
  'legendWidgetsOnMap'
]
