export default beforeEnter => {
  return {
    beforeEnter,
    name: 'explorer',
    path: 'explorer',
    component: () => import('@/modules/explorer/explorer'),
    meta: {
      title: 'explorer'
    }
  }
}
