<template>
  <div
    ref="group"
    class="r-radio-group"
  >
    <r-text
      v-if="title"
      color-type="subhead"
    >
      {{ title }}
    </r-text>
    <div
      class="r-radio-group__list"
      :class="{ col }"
    >
      <r-radio
        v-for="radio in radios"
        :id="radio.componentOptions.propsData.id"
        :key="radio.componentOptions.propsData.id"
        class="r-radio-group__list-item"
        :class="{ col }"
        :checked="radio.componentOptions.propsData.id === value"
        :disabled="radio.componentOptions.propsData.disabled"
        :caption="radio.componentOptions.propsData.caption"
        @change="$emit('change', radio.componentOptions.propsData.id)"
      >
        {{ radio.componentInstance.text }}
      </r-radio>
    </div>
    <!-- FIXME: ???-->
    <div v-show="false">
      <slot />
    </div>
    <!-- FIXME: ???-->
  </div>
</template>

<script>
export default {
  name: 'RRadioGroup',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    col: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      radios: []
    }
  },
  mounted() {
    this.radios = this.$slots.default

    this.observer = new MutationObserver(
      function () {
        this.radios = this.$slots.default
      }.bind(this)
    )

    this.observer.observe(this.$refs.group, {
      attributes: true,
      childList: true,
      characterData: true,
      subtree: true
    })
  }
}
</script>

<style lang="scss" scoped>
.r-radio-group {
  @include grid-column;

  .col {
    display: flex;
  }

  &__list-item {
    display: flex;
    flex: 1;

    &.col {
      padding: 0 0 0 0.5rem;

      &:not(:last-child) {
        @include border-right;
      }
    }
  }
}
</style>
