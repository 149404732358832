export const jsonToGeojson = (data, geomField = 'geom') => {
  return new Promise((res, rej) => {
    const j2gjWorker = new Worker(new URL('./json-to-geojson', import.meta.url))

    j2gjWorker.postMessage({ data, geomField })

    j2gjWorker.onmessage = event => {
      res(event.data)
    }

    j2gjWorker.onerror = error => {
      rej(error)
    }
  })
}
