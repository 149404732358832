<template>
  <div>
    <r-icon
      v-if="loading"
      name=" "
      :size="18"
      loading
    />
    <div
      v-else
      :class="['checkbox', `checkbox--${state}`]"
    />
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    state: {
      // checked, unchecked, indeterminate
      type: String,
      default: 'unchecked'
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox {
  width: 18px;
  height: 18px;
  border-radius: $border-radius;
  border: 1px solid $icons-low-contrast;
  pointer-events: none;
  position: relative;

  &--checked {
    background: $accent-primary;
    border: none;

    &::before {
      content: '';
      position: absolute;
      top: 3px;
      left: 7px;
      width: 3px;
      height: 8px;
      border-right: 0.1rem solid $white;
      border-bottom: 0.1rem solid $white;
      transform: rotate(45deg);
    }
  }
  &--indeterminate {
    background: $icons-high-contrast;
    border: none;

    &::before {
      content: '';
      position: absolute;
      top: 7px;
      left: 4px;
      width: 10px;
      height: 1px;
      border-bottom: 0.1rem solid $white;
    }
  }
  &--unchecked {
    background: transparent;
  }
}
</style>
