import vue from 'vue'
import { structure, nonClearableFields } from './structure'
import { cloneDeep } from 'HELPERS'

export default {
  state: {
    ...cloneDeep(structure)
  },
  getters: {
    getGisField: state => field => state[field],
    getGisActiveLayers: state => state.activeLayers,
    getGisLayersZIndex: state => state.zIndex
  },
  mutations: {
    GIS_STORE_RESET(state) {
      for (const field in structure) {
        if (nonClearableFields.includes(field)) continue
        state[field] = cloneDeep(structure[field])
      }
    },
    GIS_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        state[field] = value
      }
    },
    GIS_PUSH(state, [field, value]) {
      state[field].push(value)
    },
    GIS_SPLICE(state, [field, value]) {
      const index = state[field].indexOf(value)

      if (index >= 0) state[field].splice(index, 1)
    },
    GIS_ASSIGN(state, { field, key, value }) {
      state[field] = Object.assign({ ...state[field] }, { [key]: value })
    }
  }
}
