<template>
  <r-select
    v-model="selectedYear"
    :error="error"
    :disabled="disabled"
    :options="availableYears"
    @change="$emit('change', $event)"
  />
</template>

<script setup>
import { ref, computed, watch } from 'vue'

// eslint-disable-next-line no-undef
const props = defineProps({
  value: {
    type: Number,
    default: null
  },
  error: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const currentYear = new Date().getFullYear()
const yearsRange = 15
const minYear = currentYear - yearsRange
const maxYear = currentYear + yearsRange

const selectedYear = ref(null)

const availableYears = computed(() => {
  const years = []
  for (let year = minYear; year <= maxYear; year++) {
    years.push({ id: year, title: String(year) })
  }

  return years
})

const propsYear = computed(() => props.value)

watch(
  propsYear,
  () => {
    selectedYear.value = propsYear.value
  },
  { immediate: true }
)
</script>

<style scoped></style>
