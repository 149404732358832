export const structure = {
  map: {},
  fields: {
    // TODO:
    taxi: null
  },
  activeLink: null,
  city: null,
  baseModel: null
}

export const nonClearableFields = ['map', 'city']
