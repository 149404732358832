export const structure = {
  map: {},
  fields: {
    events: null,
    applications: null,
    cameras: null,
    info_tables: null,
    dits: null,
    meteostations: null
  },
  city: null,
  tables: {},
  filters: {
    cameras: []
  },
  objectCard: null,
  cameraCardId: null,
  meteostation: false,
  meteostationId: null,
  services: {
    eventTypes: null,
    executors: null
  },
  statuses: {
    cameras: null,
    events: null,
    applications: null
  },
  layerNames: {},
  hasObjectChanges: false
}

export const nonClearableFields = ['city', 'map']
