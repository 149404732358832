const getBadgeColor = type => {
  if (!type) return 'transparent'

  return `var(--accent-${type})`
}

export default {
  inserted: function (el, binding) {
    if (!binding.value) return

    el.style.cssText += ';position: relative !important;'
    const badge = document.createElement('span')

    badge.classList.add('v-badge')

    const cssStyleString = `position: absolute; 
    top: 4px; 
    right: ${binding.value?.button ? '4px' : '-8px'};
    z-index: 10; 
    font-size: 10px; 
    border-radius: ${binding.value?.text ? '2px' : '50%'};
    padding:${binding.value?.text ? '2px' : ''};
    width:${binding.value?.text ? '' : '8px'};
    height:${binding.value?.text ? '' : '8px'};
    display: ${binding.value?.show ? 'block' : 'none'};
    color: var(--text-primary);
    background-color: ${getBadgeColor(binding.value?.color)}; 
    cursor: ${binding.value?.title ? 'pointer' : ''};`

    if (binding.value?.text) {
      badge.innerHTML = binding.value.text
      badge.style.cssText = `${cssStyleString}`
    } else {
      badge.style.cssText = `${cssStyleString}`
    }

    if (binding.value?.title) {
      badge.title = binding.value.title
    }

    el.appendChild(badge)
  },

  update: function (el, binding) {
    const badge = el?.querySelector('.v-badge')

    if (badge && binding.value?.show === false) {
      badge.style.display = 'none'
    } else if (badge) {
      badge.style.backgroundColor = getBadgeColor(binding.value?.color)
      badge.innerHTML = binding.value?.text || ''
      badge.style.display = 'block'
    }
  },

  unbind: function (el) {
    const badge = el?.querySelector('.v-badge')

    if (badge) el.removeChild(badge)
  }
}
